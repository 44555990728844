@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.contentWrapper {
  background-color: $light-gray;

  @media #{$desktop} {
    padding: 300px 115px 115px;
  }

  @media #{$tablet} {
    padding: 50px 95px 170px;
  }

  @media #{$mobile} {
    padding: 25px 15px 70px;
  }
}

.title {
  margin: 0 auto;

  @media #{$desktop} {
    width: 485px !important;
    margin-bottom: 30px;
  }

  @media #{$tablet} {
    width: 400px !important;
    margin-bottom: 15px;
  }

  @media #{$mobile} {
    margin-bottom: 15px;
  }
}

.subtitle {
  @media #{$mobile} {
    margin: 0 auto;
    width: 320px !important;
  }
}

.image {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 85px;

  @media #{$tablet} {
    margin-top: 65px;
  }

  & > * {
    width: 660px !important;

    @media #{$mobile} {
      margin-top: 70px;
      width: 180px !important;
    }

    @media #{$tablet} {
      width: 550px !important;
      margin-top: 15px;
    }

    @media #{$mobile} {
      width: 360px !important;
      margin-top: 15px;
    }
  }
}
